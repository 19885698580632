@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
    color: #444444;
    background-color: #ffffff;
    background-size: 100% 720px;
    background-repeat: no-repeat;
    font-family: 'Lato', sans-serif;
}

.main {
    padding: 0;
    overflow-x: hidden;
}

.navbar {
    z-index: 999;
}

.navbar-brand img {
    width: 140px;
}

.navbar-brand {
    color: #2ba7df;
    font-size: 18px;
    padding: 10px 0;
}

.navbar {
    padding: 0 1rem;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.heading {
    height: 40px;
}

.profile .avatar {
    width: 26px;
    height: 26px;
    margin-bottom: -3px;
    border-radius: 50%;
}

.heading .title h1 {
    font-size: 22px;
    color: #000000;
}

.menu {
    background: #e7ebf0;
    min-height: 1000px;
}

.menu ul {
    padding: 0;
}

.menu .btn {
    color: #2ba7df;
}

.menu ul li {
    text-align: left;
    padding: 10px 0;
}

.menu ul li > a {
    color: #636363;
    text-decoration: none;
}

.menu ul li.active > a {
    font-weight: 800;
}

.menu span {
    font-size: 18px;
}

.menu .dropdown-menu span {
    font-size: 15px;
}

.menu .dropdown-menu.show {
    display: block;
    position: static !important;
    width: 100%;
    -webkit-transform: none !important;
            transform: none !important;
}

.menu .dropdown-menu li {
    padding: 5px 0;
}

.menu .dropdown-menu {
    border: 0;
    border-radius: 0;
    background: none;
    padding: 0 25px;
}

.card {
    background: #ffff;
    border-radius: 3px;
    padding: 10px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 4px 12px 1px rgba(0, 0, 0, 0.08);
}

.content {
    min-height: 1000px;
    padding: 0 15px;
}

.content .title {

}

.card-widget {
    border: solid 3px #efefef;
    text-align: center;
    padding: 10px 5px;
    min-height: 110px;
}

.card-widget .counter .total {
    font-size: 2em;
    font-weight: 700;
    margin-bottom: 0;
}

.card-widget .percentage {
    font-size: 18px;
    font-weight: 100;
    margin: 0;
}

.card-widget .title {
    margin-bottom: 0;
    font-weight: 600;
}

.nav-tabs .nav-link.active {
    border-color: transparent transparent #3cb9f2;
}

.nav-tabs .nav-link {
    color: #ccc;
    font-size: 17px;
    font-weight: 700;
    border: 0;
    border-bottom: 3px solid transparent;
}

.daterangepicker {
    border: none;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 4px 12px 1px rgba(0, 0, 0, 0.08);
}

.daterangepicker:after, .daterangepicker:before {
    border: none;
}

.btn-link {
    font-weight: 400;
    color: #00c2ff;
    background-color: transparent;
}

.btn-link:hover {
    color: #14b2f0;
    text-decoration: none;
    background-color: transparent;
    border-color: transparent;
}

.btn-link:focus {
    text-decoration: none;
    border-color: transparent;
    box-shadow: none;
}

.btn-link:disabled {
    color: #6c757d;
    pointer-events: none;
}

.input-group-append .btn, .input-group-prepend .btn {
    z-index: 0;
}

.form-control:focus {
    box-shadow: none;
}

.border-gray {
    border-color: #ddd;
}

/* Absolute Center Spinner */
.loading {
    position: fixed;
    z-index: 999;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 50px;
    height: 50px;
}

/* Transparent Overlay */
.loading:before {
    content: '';
    display: block;
    /*position: fixed;*/
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background-color: rgba(255,255,255,0.5);*/
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.loading:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 50px;
    height: 50px;
    margin-top: -0.5em;

    border: 5px solid rgba(33, 150, 243, 1.0);
    border-radius: 100%;
    border-bottom-color: transparent;
    -webkit-animation: spinner 1s linear 0s infinite;
    animation: spinner 1s linear 0s infinite;


}

/* Animation */

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.under-construction {
    position: fixed;
    z-index: 999;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100px;
    height: 50px;
    text-align: center;
    font-size: 15px;
}

.svg-map {
    width: 300px;
    height: auto;
    stroke: #ffffff;
    stroke-width: 1;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.svg-map__location {
    fill: #a6adc2;
    cursor: pointer;
}

.svg-map__location:focus, .svg-map__location:hover {
    fill: #3B729F;
    outline: 0;
}

.svg-map__location[aria-checked=true] {
    fill: #3B729F;
}

.map__tooltip {
    position: fixed;
    width: 100px;
    padding: 10px;
    border: 1px solid darkgray;
    background-color: white;
}

.governorate-name {
    font-size: 25px;
}

.map-keys {
    list-style: none;
}

.map-keys .key {
    margin-bottom: 5px;
}

.map-keys .key span {
    padding: 5px 20px;
    margin-right: 5px;
}

.map-keys .first-key span {
    background-color: #8b8d99;
}

.map-keys .second-key span {
    background-color: #3B729F;
}

.map-keys .third-key span {
    background-color: #345076;
}

.map-keys .fourth-key span {
    background-color: #2b3857;
}

.map-keys .fifth-key span {
    background-color: #181d34;
}

.ReactModal__Overlay {
    overflow-x: hidden;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    -webkit-transition: opacity 150ms ease-out;
    transition: opacity 150ms ease-out;
}

.ReactModal__Content {
    -webkit-transform: scale(0.5) rotateX(-30deg);
    transform: scale(0.5) rotateX(-30deg);
}

.ReactModal__Content--after-open {
    -webkit-transform: scale(1) rotateX(0deg);
    transform: scale(1) rotateX(0deg);
    -webkit-transition: all 150ms ease-in;
    transition: all 150ms ease-in;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.ReactModal__Content--before-close {
    -webkit-transform: scale(0.5) rotateX(30deg);
    transform: scale(0.5) rotateX(30deg);
    -webkit-transition: all 150ms ease-in;
    transition: all 150ms ease-in;
}

.modal-content {
    margin-top: 100px;
}

.ranges ul li:nth-child(2), .ranges ul li:nth-child(7), .ranges ul li:nth-child(9), .ranges ul li:nth-child(12) {
    margin-bottom: 5px;
}

.ranges ul li:nth-child(2)::after, .ranges ul li:nth-child(7)::after, .ranges ul li:nth-child(9)::after, .ranges ul li:nth-child(12)::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    border-top: 1px solid #979797;
    margin-top: 26px;
}
@font-face {
    font-family: 'Line Awesome Free';
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url(/static/media/la-regular-400.2746742c.eot);
    src: url(/static/media/la-regular-400.2746742c.eot?#iefix) format("embedded-opentype"), url(/static/media/la-regular-400.88d9d941.woff2) format("woff2"), url(/static/media/la-regular-400.338f6f87.woff) format("woff"), url(/static/media/la-regular-400.87dab6ff.ttf) format("truetype"), url(/static/media/la-regular-400.55c57a74.svg#lineawesome) format("svg"); }


.ReactTable .rt-thead.-header {
    box-shadow: none;
}

.ReactTable .rt-thead .rt-th {
    color: #8b8d99;
    padding: 15px 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-shadow: none !important;
    border-bottom: solid 1px #ddd;
}

.ReactTable .rt-thead .rt-tr {
    text-align: left;
}

.Table__itemCount {
    /* margin-top: 10px; */
    font-size: 14px;
}

.Table__pagination {
    display: flex;
    justify-content: center;
    padding: 20px 10px;
}

.Table__pageButton {
    font-size: 15px;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.Table__pageButton:disabled {
    cursor: not-allowed;
    color: gray;
}

.Table__pageButton--active {
    color: #45b3e3;
    font-weight: bold;
}

.ReactTable .-sort-desc {
    box-shadow: none !important;
}

.ReactTable .-sort-desc:before {
    font-family: 'Line Awesome Free';
    font-weight: 900;
    content: "\f107";
    position: absolute;
    right: 5px;
}

.ReactTable .-sort-asc {
    box-shadow: none !important;
}

.ReactTable .-sort-asc:before {
    font-family: 'Line Awesome Free';
    font-weight: 900;
    content: "\f106";
    position: absolute;
    right: 5px;
}


