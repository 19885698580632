@font-face {
    font-family: 'Line Awesome Free';
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url("../../node_modules/line-awesome/dist/line-awesome/fonts/la-regular-400.eot");
    src: url("../../node_modules/line-awesome/dist/line-awesome/fonts/la-regular-400.eot?#iefix") format("embedded-opentype"), url("../../node_modules/line-awesome/dist/line-awesome/fonts/la-regular-400.woff2") format("woff2"), url("../../node_modules/line-awesome/dist/line-awesome/fonts/la-regular-400.woff") format("woff"), url("../../node_modules/line-awesome/dist/line-awesome/fonts/la-regular-400.ttf") format("truetype"), url("../../node_modules/line-awesome/dist/line-awesome/fonts/la-regular-400.svg#lineawesome") format("svg"); }


.ReactTable .rt-thead.-header {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.ReactTable .rt-thead .rt-th {
    color: #8b8d99;
    padding: 15px 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-shadow: none !important;
    border-bottom: solid 1px #ddd;
}

.ReactTable .rt-thead .rt-tr {
    text-align: left;
}

.Table__itemCount {
    /* margin-top: 10px; */
    font-size: 14px;
}

.Table__pagination {
    display: flex;
    justify-content: center;
    padding: 20px 10px;
}

.Table__pageButton {
    font-size: 15px;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.Table__pageButton:disabled {
    cursor: not-allowed;
    color: gray;
}

.Table__pageButton--active {
    color: #45b3e3;
    font-weight: bold;
}

.ReactTable .-sort-desc {
    box-shadow: none !important;
}

.ReactTable .-sort-desc:before {
    font-family: 'Line Awesome Free';
    font-weight: 900;
    content: "\f107";
    position: absolute;
    right: 5px;
}

.ReactTable .-sort-asc {
    box-shadow: none !important;
}

.ReactTable .-sort-asc:before {
    font-family: 'Line Awesome Free';
    font-weight: 900;
    content: "\f106";
    position: absolute;
    right: 5px;
}

